import { useNav } from "@pomle/react-router-paths";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { Typography } from "render/ui/presentation/Typography";
import { BigButton } from "render/ui/trigger/BigButton";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import { homeEvent } from "../../tracking";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function GiveConsentStage() {
  const nav = {
    consent: useNav(paths.consent),
  };
  const { trackEvent } = useTracking();

  return (
    <>
      <FadeIn offset={112}>
        <div className={styles.message}>{<Trans.Greeting />}</div>
      </FadeIn>
      <FadeIn offset={176}>
        <BigButton
          onClick={() => {
            trackEvent(homeEvent.giveConsentClick());
            nav.consent.go({});
          }}
        >
          <>
            <ArrowIcon className={styles.arrow} />
            <Typography variant="body-label-m" color="subtle">
              <Trans.GiveConsentAction />
            </Typography>
          </>
        </BigButton>
      </FadeIn>
    </>
  );
}
