import { classNames } from "@pomle/classnames";
import { ReactComponent as ApplePayIcon } from "assets/apple-pay.svg";
import { ReactComponent as ArrowIcon } from "assets/arrow-right.svg";
import { ReactComponent as GooglePayIcon } from "assets/google-pay.svg";
import { PropsWithChildren, useMemo, useState } from "react";
import styles from "./styles.module.sass";
type Variant = "primary" | "danger" | "secondary" | "suggestion" | "wallet";

interface ActionButtonProps {
  variant?: Variant;
  walletVariant?: string;
  onClick: () => void;
  hideIcon?: boolean;
  style?: Partial<React.CSSProperties>;
  disabled?: boolean;
  children: React.ReactNode;
  form?: string;
}

export function ActionButton({
  variant = "primary",
  walletVariant,
  onClick,
  children,
  hideIcon,
  style = {},
  disabled,
  form,
}: PropsWithChildren<ActionButtonProps>) {
  const [state, setState] = useState("");
  const variantClass = useMemo(() => {
    const record: Record<Variant, string> = {
      primary: styles.primary,
      danger: styles.danger,
      secondary: styles.secondary,
      suggestion: styles.suggestion,
      wallet: styles.wallet,
    };

    return record[variant];
  }, [variant]);

  return (
    <button
      disabled={disabled}
      style={{ ...style }}
      className={classNames(styles.button, state, variantClass)}
      onClick={() => {
        setState(styles.mouseout);
        onClick();
      }}
      onMouseOver={() => {
        if (state === styles.mouseover || disabled) {
          return;
        }
        setState(styles.mouseover);
      }}
      onMouseOut={() => {
        if (state === styles.mouseout || disabled) {
          return;
        }

        setState(styles.mouseout);
      }}
      form={form}
    >
      {children}

      <div
        style={{ display: hideIcon || variant === "wallet" ? "none" : "flex" }}
        className={styles.icons}
      >
        <ArrowIcon className={classNames(styles.rightArrow, variantClass)} />
        <ArrowIcon className={classNames(styles.leftArrow, variantClass)} />
      </div>
      <div style={{ display: variant === "wallet" ? "flex" : "none" }}>
        {walletVariant === "apple" && <ApplePayIcon />}
        {walletVariant === "google" && <GooglePayIcon />}
      </div>
    </button>
  );
}
