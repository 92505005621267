import { Risk } from "@cur8/health-risks-calc";
import React from "react";
import { Badge } from "render/ui/presentation/Badge";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Typography } from "render/ui/presentation/Typography";
import { StudySymbol } from "render/ui/symbol/StudySymbol";
import { useBadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/hooks/useBadgeState";
import { BadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/types";
import { Trans } from "../trans";
import styles from "./styles.module.sass";

interface MetricProps {
  label: React.ReactNode;
  value: number | undefined;
  previousValue: number | undefined;
  metricRating: Risk | undefined;
  subLabel?: React.ReactNode;
  showPreviousInsteadOfChange?: boolean;
  isClinicalStudy?: boolean;
}

type MetricComponentProps = {
  badgeState: BadgeState | undefined;
  previousValueText: React.ReactNode;
  value: string | number;
} & Pick<MetricProps, "isClinicalStudy" | "label" | "subLabel">;

function MetricComponent({
  isClinicalStudy,
  label,
  badgeState,
  previousValueText,
  subLabel,
  value,
}: MetricComponentProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {isClinicalStudy ? (
          <div className={styles.labelWrapper}>
            <Typography
              whiteSpace="nowrap"
              variant="body-label-s"
              color="default"
            >
              {label}
            </Typography>
            <StudySymbol />
          </div>
        ) : (
          <Typography
            whiteSpace="nowrap"
            variant="body-label-s"
            color="default"
          >
            {label}
          </Typography>
        )}
        {!!badgeState && (
          <Badge variant={badgeState.variant}>{badgeState.text}</Badge>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.values}>
          <Typography variant="number-s" color="default">
            {value}
          </Typography>
          {subLabel && (
            <Typography variant="body-s" color="subtle">
              {subLabel}
            </Typography>
          )}
        </div>
        {previousValueText ? (
          <Typography variant="body-xs" color="subtle">
            {previousValueText}
          </Typography>
        ) : undefined}
      </div>
    </div>
  );
}

export function MetricLoading({
  label,
  isClinicalStudy,
}: Pick<MetricProps, "label" | "isClinicalStudy">) {
  return (
    <div className={styles.container}>
      {isClinicalStudy ? (
        <div className={styles.labelWrapper}>
          <Typography whiteSpace="nowrap" variant="body-s">
            {label}
          </Typography>
          <StudySymbol />
        </div>
      ) : (
        <Typography whiteSpace="nowrap" variant="body-s">
          {label}
        </Typography>
      )}
      <div className={styles.skeleton}>
        <Skeleton />
      </div>
    </div>
  );
}

export function Metric({
  label,
  value,
  previousValue,
  subLabel,
  metricRating,
  showPreviousInsteadOfChange,
  isClinicalStudy,
}: MetricProps) {
  const current = value !== undefined;
  const previous = previousValue !== undefined;
  const badgeState = useBadgeState({ value, metricRating });
  if (!current && !previous) {
    return (
      <MetricComponent
        isClinicalStudy={isClinicalStudy}
        label={label}
        badgeState={badgeState}
        subLabel={subLabel}
        value="-"
        previousValueText={undefined}
      />
    );
  }

  if (!current && previous) {
    return (
      <MetricComponent
        isClinicalStudy={isClinicalStudy}
        label={label}
        badgeState={badgeState}
        subLabel={subLabel}
        previousValueText={<Trans.Previous value={previousValue} />}
        value="-"
      />
    );
  }

  if (current && !previous) {
    return (
      <MetricComponent
        isClinicalStudy={isClinicalStudy}
        label={label}
        badgeState={badgeState}
        subLabel={subLabel}
        previousValueText={undefined}
        value={value}
      />
    );
  }

  if (current && previous) {
    const change = value - previousValue;
    const changeNumber = showPreviousInsteadOfChange
      ? previousValue
      : +Math.abs(change).toFixed(2);
    const changeText = showPreviousInsteadOfChange ? (
      <Trans.Previous value={changeNumber} />
    ) : change >= 0 ? (
      <Trans.Increased value={changeNumber} />
    ) : (
      <Trans.Decreased value={changeNumber} />
    );

    return (
      <MetricComponent
        isClinicalStudy={isClinicalStudy}
        label={label}
        badgeState={badgeState}
        subLabel={subLabel}
        previousValueText={changeText}
        value={value}
      />
    );
  }
}
