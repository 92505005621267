import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as EightCornerStarIcon } from "assets/eight-corner-star.svg";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { BigButton } from "render/ui/trigger/BigButton";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookStageProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
}

export function BookStage({ patient, bookingTokens }: BookStageProps) {
  const nav = {
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
  };

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  const hasBookingTokens = useMemo(() => {
    return (
      bookingTokens.filter((bookingToken) => bookingToken.availableForBooking)
        .length > 0
    );
  }, [bookingTokens]);

  return (
    <div className={styles.BookStage}>
      {!canBook && hasBookingTokens && (
        <FadeIn offset={112}>
          <Typography variant="display-s">
            <Trans.ScanPrebooked />
          </Typography>
          <Typography variant="title-m">
            <Trans.WeWillReachYou />
          </Typography>
        </FadeIn>
      )}

      {!canBook && !hasBookingTokens && (
        <>
          <FadeIn offset={112}>
            <Typography variant="display-s">
              <Trans.NoToken.Welcome />
            </Typography>
            <Typography variant="title-m">
              <Trans.NoToken.ReserveYourAppointment />
            </Typography>
          </FadeIn>
          <FadeIn offset={176}>
            <BigButton
              onClick={() => {
                nav.payment.go({});
              }}
            >
              <div className={styles.button}>
                <div className={styles.icon}>
                  <EightCornerStarIcon />
                </div>
                <div className={styles.text}>
                  <Typography variant="body-label-m" color="default">
                    <Trans.NoToken.BuyButton />
                  </Typography>
                </div>
              </div>
            </BigButton>
          </FadeIn>
        </>
      )}

      {canBook && (
        <>
          <FadeIn offset={112}>
            <Typography variant="display-s">
              <Trans.Greeting patient={patient} />
            </Typography>
          </FadeIn>
          <FadeIn offset={176}>
            <BigButton
              onClick={() => {
                if (hasBookingTokens) {
                  nav.booking.go({});
                } else {
                  nav.payment.go({});
                }
              }}
            >
              <div className={styles.button}>
                <div className={styles.icon}>
                  <EightCornerStarIcon />
                </div>
                <div className={styles.text}>
                  <Typography variant="body-label-m" color="default">
                    <Trans.Book />
                  </Typography>
                  <Typography variant="body-s">
                    <Trans.SlotStatus.Available />
                  </Typography>
                </div>
              </div>
            </BigButton>
          </FadeIn>
        </>
      )}
    </div>
  );
}
