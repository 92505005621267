import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { MappedConsent } from "lib/consents/mapConsents";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useIsSmallViewport } from "render/hooks/useIsSmallViewport";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { ConsentDetails } from "render/views/ProfileView/components/ConsentDocument/components/ConsentDetails";
import { ConsentState } from "render/views/ProfileView/components/ConsentDocument/components/ConsentState";
import { SharedTrans } from "render/views/trans";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ConsentDocumentProps {
  consent: MappedConsent;
  onClose: () => void;
}

export function ConsentDocument({ consent, onClose }: ConsentDocumentProps) {
  const contactUsPopup = useContactUsPopup();
  const isSmallViewport = useIsSmallViewport();

  return (
    <FullScreenPageLayout
      className={styles.page}
      data-testid="consent-document"
    >
      <header className={styles.header}>
        <LogoHeader
          hideLogo={!isSmallViewport}
          leftElement={
            <IconButton
              ariaLabel={Trans.Arrow()}
              icon={<CrossIcon />}
              onClick={onClose}
            />
          }
          rightElement={
            isSmallViewport && (
              <IconButton
                ariaLabel={SharedTrans.ContactUs()}
                icon={<QuestionIcon display="block" />}
                onClick={contactUsPopup.emit}
              />
            )
          }
        />
      </header>
      <main className={styles.body}>
        <ConsentState signature={consent.relevantSignature} />
        <ConsentDetails consent={consent} />
      </main>
    </FullScreenPageLayout>
  );
}
