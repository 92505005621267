import { APITypesV1 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/chat/chat-36x36x.svg";
import { ReactNode, useCallback } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Typography } from "render/ui/presentation/Typography";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { ReactComponent as ClockSmallIcon } from "./assets/clock-small.svg";

import styles from "./styles.module.sass";
import { Trans } from "./trans";

function Layout({ text, actions }: { text?: ReactNode; actions?: ReactNode }) {
  return (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.actions}>{actions}</div>
    </>
  );
}

function PendingState() {
  const { stepUpAccess } = useMSAL();
  const redirectUri = useNav(paths.appointmentSummary);

  const onClick = useCallback(() => {
    stepUpAccess("idv", redirectUri.to({}));
  }, [stepUpAccess, redirectUri]);

  return (
    <Layout
      text={
        <>
          <Typography variant="display-s" color="default">
            <Trans.Pending.Title />
          </Typography>
          <div className={styles.subText}>
            <Typography variant="body-m" color="default">
              <Trans.Pending.Paragraph1 />
            </Typography>
            <div className={styles.textWithIcon}>
              <ClockSmallIcon />
              <Typography variant="body-s" color="default">
                <Trans.Pending.SubParagraph1 />
              </Typography>
            </div>
          </div>
        </>
      }
      actions={
        <ActionButton onClick={onClick} variant="suggestion">
          <Trans.Pending.VerifyCta />
        </ActionButton>
      }
    />
  );
}

function SuccessState() {
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Success.Title />
          </Typography>
          <Typography style={{ marginTop: "12px" }} variant="body-m">
            <Trans.Success.Paragraph />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={window.location.reload} variant="suggestion">
          <Trans.Success.CTA />
        </ActionButton>
      }
    />
  );
}

function SubmittedState() {
  const nav = {
    home: useNav(paths.root),
  };
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Submitted.Title />
          </Typography>
          <Typography variant="body-m" color="default">
            <Trans.Submitted.Text />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={nav.home.on({})} variant="suggestion">
          <Trans.Submitted.Done />
        </ActionButton>
      }
    />
  );
}

function DeclinedState() {
  const { stepUpAccess } = useMSAL();
  const redirectUri = useNav(paths.appointmentSummary);

  const handleTryAgain = useCallback(() => {
    stepUpAccess("idv", redirectUri.to({}));
  }, [stepUpAccess, redirectUri]);
  const nav = {
    home: useNav(paths.root),
  };

  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Fail.Title />
          </Typography>
          <Typography variant="body-m" color="default">
            <Trans.Fail.Paragraph1 />
          </Typography>
        </>
      }
      actions={
        <>
          <ActionButton onClick={handleTryAgain} variant="suggestion">
            <Trans.Fail.TryAgain />
          </ActionButton>
          <ActionButton onClick={nav.home.on({})} variant="secondary">
            <Trans.Fail.Skip />
          </ActionButton>
        </>
      }
    />
  );
}

export function AppointmentIdentityRequiredView() {
  const contactUsPopup = useContactUsPopup();
  const nav = {
    home: useNav(paths.root),
  };

  const controls = {
    goBack: useCallback(() => nav.home.go({}), [nav.home]),
    openContactUs: contactUsPopup.emit,
  };

  const identityVerificationStatusQuery = useIdentityVerificationStatus();
  const identityVerificationStatus =
    identityVerificationStatusQuery.data?.identityVerificationStatus;

  return (
    <FullScreenPageLayout>
      <div className={styles.OnboardingIdvResultView}>
        <div className={styles.sticky}>
          <LogoHeader
            leftElement={
              <IconButton onClick={controls.goBack} icon={<CrossIcon />} />
            }
            rightElement={
              <IconButton
                icon={<QuestionIcon display="block" />}
                onClick={controls.openContactUs}
              />
            }
          />
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            {identityVerificationStatus ? (
              <>
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Verified ? (
                  <SuccessState />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Pending ? (
                  <PendingState />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Declined ? (
                  <DeclinedState />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Submitted ? (
                  <SubmittedState />
                ) : null}
              </>
            ) : (
              <Layout
                text={
                  <>
                    <div className={styles.skeletonBig}>
                      <Skeleton />
                    </div>
                    <div className={styles.skeletonSmall}>
                      <Skeleton />
                    </div>
                  </>
                }
                actions={
                  <div className={styles.skeletonCTA}>
                    <Skeleton />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
