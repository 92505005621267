import { ReactComponent as Check } from "assets/check.svg";
import { ReactComponent as SquircleOutline } from "assets/squircle-outline.svg";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import styles from "./styles.module.sass";

type CheckboxProps = JSX.IntrinsicElements["input"] & {
  bordered?: boolean;
  inverted?: boolean;
};

export function Checkbox({
  bordered,
  className,
  children,
  inverted,
  ...props
}: CheckboxProps) {
  return (
    <label
      className={[className, styles.label].filter(Boolean).join(" ")}
      data-bordered={bordered}
      data-inverted={inverted}
    >
      <div className={styles.inputContainer}>
        <input {...props} className={styles.input} type="checkbox" />
        <SquircleOutline className={styles.squircleOutline} />
        <Squircle className={styles.squircle} />
        <Check className={styles.check} />
      </div>
      {children}
    </label>
  );
}
