import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { Typography } from "render/ui/presentation/Typography";
import { Button } from "render/ui/trigger/Button";
import { Card } from "render/ui/trigger/Card";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

type ConfirmCreationProps = {
  onCancel?: () => void;
  onConfirm?: () => void;
};

export function ConfirmCreation({ onCancel, onConfirm }: ConfirmCreationProps) {
  return (
    <Card elevation={1}>
      <div className={styles.text}>
        <div className={styles.header}>
          <Typography variant="title-m">
            <Trans.CreateFiles />
          </Typography>
          <IconButton icon={<CrossIcon />} onClick={onCancel} />
        </div>
        <div className={styles.content}>
          <Typography variant="body-m" color="subtle">
            <Trans.AreYouSure />
          </Typography>
        </div>
      </div>
      <div className={styles.row}>
        <Button variant="outlined" onClick={onCancel}>
          <Typography variant="body-label-m">
            <Trans.Cancel />
          </Typography>
        </Button>
        <Button variant="active" onClick={onConfirm}>
          <Typography variant="body-label-m">
            <Trans.Confirm />
          </Typography>
        </Button>
      </div>
    </Card>
  );
}
