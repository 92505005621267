import { ReactComponent as CopyIcon } from "assets/copy.svg";
import { copyToClipboard } from "lib/copyToClipboard";
import { useSnackbar } from "notistack";
import { type ReactNode } from "react";
import { useTimeOut } from "render/hooks/useTimeOut";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

// copied to clipboard notification duration in ms
const duration = 2000;

type CopyToClipboardProps = {
  children?: ReactNode;
};

export function CopyToClipboard({ children }: CopyToClipboardProps) {
  const timeout = useTimeOut(duration);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <button
      className={styles.copyToClipboard}
      onClick={(event) => {
        const text = event.currentTarget.textContent;
        if (!text) {
          return;
        }
        copyToClipboard(text);
        if (timeout.pending()) {
          return;
        }
        timeout.startTimeout();
        enqueueSnackbar(<Trans.CopiedToClipboard />, {
          autoHideDuration: duration,
          variant: "success",
        });
      }}
    >
      <Typography variant="body-label-m">{children}</Typography>
      <CopyIcon />
    </button>
  );
}
