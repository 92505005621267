import { localize } from "render/context/InternationalizationContext";

function emergencyNumber(countryCode: string) {
  switch (countryCode) {
    case "GB":
      return 999;
    default:
      return 112;
  }
}

export function contactUsNumber(countryCode: string) {
  switch (countryCode) {
    case "GB":
      return "+44 7701 411315";
    default:
      return "+46 72 600 96 90";
  }
}

const ContactUs = {
  Title: localize({
    en_GB: <>Contact Us</>,
    sv_SE: <>Kontakta oss</>,
  }),
  Text: localize<{ countryCode: string }>({
    en_GB: ({ countryCode }) => (
      <>
        Do not hesitate to contact us if you have any questions regarding your
        visit or your results. Please text us at {contactUsNumber(countryCode)}.
      </>
    ),
    sv_SE: ({ countryCode }) => (
      <>
        Tveka inte på att kontakta oss om du har några frågor angående ditt
        besök och dina resultat. Skriv gärna till oss på{" "}
        {contactUsNumber(countryCode)}.
      </>
    ),
  }),
  ChestPain: localize<{ countryCode: string }>({
    en_GB: ({ countryCode }) => (
      <>
        If you’re experiencing severe pain or symptoms such as difficulty
        breathing, chest, abdominal pain or symptoms similar to a stroke, please
        call {emergencyNumber(countryCode)} immediately.
      </>
    ),
    sv_SE: ({ countryCode }) => (
      <>
        Om du upplever svår smärta eller symtom som andningssvårigheter, bröst-,
        buksmärtor eller symtom som liknar en stroke, ring{" "}
        {emergencyNumber(countryCode)} omedelbart.
      </>
    ),
  }),
  TextUs: localize({
    en_GB: <>Text Us</>,
    sv_SE: <>SMSa oss</>,
  }),
  Close: localize({
    en_GB: "Close",
    sv_SE: "Close",
  }),
};

export const Trans = {
  ContactUs,
};
