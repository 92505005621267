import { ViewStack } from "@pomle/react-viewstack";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { useState } from "react";
import { ReactComponent as ArrowIcon } from "render/ui/icons/arrow.svg";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Expandable } from "render/ui/presentation/Expandable";
import { Typography } from "render/ui/presentation/Typography";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";

interface StepViewProps {
  caption?: React.ReactNode;
  subCaption?: React.ReactNode;
  children?: React.ReactNode;
  cta?: React.ReactNode;
  onPrev?: () => void;
  showCloseIcon?: boolean;
  currentQuestion: number;
  totalQuestionCount: number;
  explanation?: {
    openText: string | JSX.Element;
    explanationText: string | JSX.Element;
    closeText: string | JSX.Element;
  };
  hideProgressBar?: boolean;
}

export function StepView({
  cta,
  onPrev,
  caption,
  children,
  currentQuestion,
  showCloseIcon,
  totalQuestionCount,
  explanation,
  hideProgressBar = false,
  subCaption,
}: StepViewProps) {
  const filled = (currentQuestion / (totalQuestionCount + 1)) * 100;
  const [expanded, setExpanded] = useState(false);

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <BurgerLayout>
          <LogoHeader
            leftElement={
              onPrev && (
                <IconButton
                  onClick={onPrev}
                  icon={showCloseIcon ? <CrossIcon /> : <ArrowIcon />}
                />
              )
            }
          />

          <form
            className={styles.StepView}
            id={String(currentQuestion)}
            onSubmit={(e) => e.preventDefault()}
          >
            <div className={styles.body}>
              <div className={styles.sticky}>
                {!hideProgressBar ? (
                  <div className={styles.progress}>
                    <div
                      className={styles.progressLine}
                      style={{
                        width: `${filled}%`,
                      }}
                    />
                    <div
                      className={styles.remainingLine}
                      style={{
                        width: `${100 - filled}%`,
                      }}
                    />
                    <progress
                      max={totalQuestionCount}
                      value={currentQuestion}
                      className={styles.screenReaderProgressBar}
                    >
                      Step {currentQuestion} of {totalQuestionCount}
                    </progress>
                  </div>
                ) : (
                  <div />
                )}
                <div className={styles.question}>
                  <div className={styles.captions}>
                    <Typography variant="title-l">{caption}</Typography>
                    {subCaption && (
                      <Typography variant="body-s">{subCaption}</Typography>
                    )}
                  </div>
                  {explanation && (
                    <div
                      className={styles.why}
                      onClick={() => {
                        setExpanded((v) => !v);
                      }}
                    >
                      <div className={styles.stacked}>
                        <Expandable isOpen={!expanded}>
                          <Typography variant="body-s" color="info">
                            {explanation.openText}
                          </Typography>
                        </Expandable>
                        <Expandable isOpen={expanded}>
                          <div className={styles.explanation}>
                            <Typography variant="body-s" color="subtle">
                              {explanation.explanationText}
                            </Typography>
                            <Typography variant="body-s" color="info">
                              {explanation.closeText}
                            </Typography>
                          </div>
                        </Expandable>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.content}>{children}</div>
            </div>
          </form>
          {cta && <div className={styles.nav}>{cta}</div>}
        </BurgerLayout>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
