import { Typography } from "render/ui/presentation/Typography";
import { ReactComponent as CheckIcon } from "./assets/checkmark.svg";
import { ReactComponent as ExIcon } from "./assets/ex.svg";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function TipsSection() {
  return (
    <div className={styles.TipsSection}>
      <Typography variant="title-m">
        <Trans.BeforeYourScan />
      </Typography>
      <ul className={styles.stepsTipsList}>
        <li>
          <CheckIcon />
          <Typography variant="body-m" color="default">
            <Trans.CheckList.Eating />
          </Typography>
        </li>
        <li>
          <CheckIcon />
          <Typography variant="body-m" color="default">
            <Trans.CheckList.Exercises />
          </Typography>
        </li>
        <li>
          <ExIcon />
          <Typography variant="body-m" color="default">
            <Trans.CheckList.Caffeine />
          </Typography>
        </li>
        <li>
          <ExIcon />
          <Typography variant="body-m" color="default">
            <Trans.CheckList.Skin />
          </Typography>
        </li>
      </ul>
    </div>
  );
}
