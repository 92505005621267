import { PropsWithChildren, useCallback, useRef } from "react";
import styles from "./styles.module.sass";

interface BackdropProps {
  active: boolean;
  isHalfWidth?: boolean;
  isSubtle?: boolean;
  isTransparent?: boolean;
  onOutsideClick?: () => void;
}

export function Backdrop({
  active,
  children,
  isHalfWidth,
  isSubtle,
  isTransparent,
  onOutsideClick,
}: PropsWithChildren<BackdropProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const onBackgroundClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target !== ref.current) {
        return;
      }
      onOutsideClick?.();
    },
    [onOutsideClick]
  );

  return (
    <div
      ref={ref}
      className={styles.Backdrop}
      data-active={active}
      data-subtle={isSubtle}
      data-transparent={isTransparent}
      onClick={onBackgroundClick}
    >
      <div className={styles.content} data-half-width={isHalfWidth}>
        {children}
      </div>
    </div>
  );
}
