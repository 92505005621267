import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { Country } from "lib/country";
import {
  FALLBACK_LOCALE,
  Locale,
  language2Locale,
} from "render/context/InternationalizationContext";
import { useSession } from "render/context/MSALContext";
import { PatientPhysicalIdentifier } from "render/fragments/patient/PatientPhysicalIdentifier";
import { paths } from "render/routes/paths";
import { DateOfBirth } from "render/ui/format/DateOfBirth";
import { InfoField } from "./components/InfoField/InfoField";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

const getPreferredLanguage = (preferredLanguage: string | undefined) => {
  const supportedLanguages: Record<Locale, string> = {
    en_GB: "English",
    sv_SE: "Swedish",
  };

  const prefLang = language2Locale(preferredLanguage ?? "");
  return supportedLanguages[prefLang || FALLBACK_LOCALE] ?? "";
};

function Location({ patient }: { patient: Patient }) {
  switch (patient.preferredCountry) {
    case Country.Sweden:
      return <Trans.Country.Sweden />;
    case Country.UK:
      return <Trans.Country.UK />;
    default:
      return undefined;
  }
}

export function PatientInfo({ patient }: { patient: Patient }) {
  const { mfa } = useSession();

  const nav = {
    editEmail: useNav(paths.profileCredentialsEditEmail),
    editLanguage: useNav(paths.profileCredentialsEditLanguage),
    editName: useNav(paths.profileCredentialsEditName),
    editPhone: useNav(paths.profileCredentialsEditPhone),
  };

  const { name, contactDetails, preferredLanguage, preferredCountry } = patient;
  const showPersonalNumber = mfa && preferredCountry === Country.Sweden;
  const showDateOfBirth = !showPersonalNumber;

  return (
    <ul className={styles.PatientInfo}>
      <li>
        <InfoField
          label={<Trans.Label.Name />}
          value={
            name?.displayName ??
            name?.firstName ?? <Trans.PlaceHolder.AddName />
          }
          onClick={nav.editName.on({})}
        />
      </li>
      {patient.dateOfBirth && showDateOfBirth ? (
        <li>
          <InfoField
            label={<Trans.Label.DateOfBirth />}
            value={
              <DateOfBirth
                date={patient.dateOfBirth}
                locale={preferredLanguage}
              />
            }
          />
        </li>
      ) : undefined}
      {showPersonalNumber ? (
        <li>
          <InfoField
            label={<Trans.Label.PersonalNumber />}
            value={<PatientPhysicalIdentifier patient={patient} />}
          />
        </li>
      ) : undefined}
      <li>
        <InfoField
          label={<Trans.Label.Cellphone />}
          value={
            contactDetails.phoneNumbers[0] ?? <Trans.PlaceHolder.AddPhone />
          }
        />
      </li>
      <li>
        <InfoField
          label={<Trans.Label.Email />}
          value={contactDetails.email ?? <Trans.PlaceHolder.AddEmail />}
          onClick={nav.editEmail.on({})}
        />
      </li>
      <li>
        <InfoField
          label={<Trans.Label.Language />}
          value={getPreferredLanguage(preferredLanguage || undefined)}
          onClick={nav.editLanguage.on({})}
        />
      </li>
      <li>
        <InfoField
          label={<Trans.Label.Location />}
          value={<Location patient={patient} />}
        />
      </li>
    </ul>
  );
}
