import { ReactComponent as Checkmark } from "assets/checkmark.svg";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type ChapterProps = {
  current: number;
  total: number;
};

export function Chapters({ current, total }: ChapterProps) {
  return (
    <div className={styles.chapters}>
      <Checkmark />
      <Typography variant="eyebrow-m">
        <Trans.ChaptersComplete current={current} total={total} />
      </Typography>
    </div>
  );
}
