import { Sticky } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import type { ReactNode } from "react";
import { useIsSmallViewport } from "render/hooks/useIsSmallViewport";
import { Backdrop } from "render/ui/layout/Backdrop";

type BackdropOrSlideProps = {
  active: boolean;
  children: ReactNode;
  onClose: () => void;
};

export function BackdropOrSlide({
  active,
  children,
  onClose,
}: BackdropOrSlideProps) {
  const isSmallViewport = useIsSmallViewport();

  const Component = isSmallViewport ? Slide : Backdrop;

  return (
    <Component
      active={active}
      direction={isSmallViewport ? Direction.Down : Direction.Right}
      isSubtle
      onOutsideClick={onClose}
    >
      <Sticky>{children}</Sticky>
    </Component>
  );
}
