import { ReactComponent as Chevron } from "assets/chevron.svg";
import { ReactNode } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface InfoFieldProps {
  label: ReactNode;
  value: ReactNode;
  onClick?: () => void;
}

export function InfoField({ label, onClick, value }: InfoFieldProps) {
  return (
    <button
      onClick={onClick}
      className={styles.InfoField}
      data-canedit={!!onClick}
    >
      <div className={styles.label}>
        <Typography variant="eyebrow-m" color="subtle">
          {label}
        </Typography>
      </div>
      <div className={styles.row}>
        <div className={styles.value}>
          <Typography variant="body-m" color="default">
            {value}
          </Typography>
        </div>
        {onClick && (
          <div className={styles.chevron}>
            <Chevron />
          </div>
        )}
      </div>
    </button>
  );
}
