import { useQueryParams } from "@pomle/react-router-paths";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { queries } from "render/routes/paths";
import { PaymentProvider } from "./components/PaymentProvider";
import {
  Checkout,
  useInitializeCheckoutMutation,
} from "./hooks/useCreateIntentMutation/useCreateIntentMutation";
import {
  Cart,
  useUpdateCartMutation,
} from "./hooks/useUpdateCartMutation/useUpdateCartMutation";
import { useValidateTokenMutation } from "./hooks/useValidateTokenMutation/useValidateTokenMutation";

type Store = {
  updateCartMutation: ReturnType<typeof useUpdateCartMutation>;
  validateTokenMutation: ReturnType<typeof useValidateTokenMutation>;
  applyDiscountCode: (payload: { code: string }) => Promise<void>;
  cart: Cart | undefined;
  checkout: Checkout | undefined;
  fullPrice: number | undefined;
  discountCode: string | undefined;
  stripe: Stripe | null;
  elements: StripeElements | null;
  discountApplied: boolean;
};

const Context = createContext<Store | undefined>(undefined);

const PRODUCT_ID = "126cc5ed-ce71-4965-845d-bf3423bdb6b9";

type OnStripeReady = {
  stripe: Stripe | null;
  elements: StripeElements | null;
};

function StripeMethods({
  children,
  onReady,
}: PropsWithChildren<{
  onReady?: (payload: OnStripeReady) => void;
}>) {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    onReady?.({ elements, stripe });
  }, [stripe, elements, onReady]);

  return children;
}

function StipeProvider(
  props: PropsWithChildren<{
    checkout: Checkout | undefined;
    onReady?: (payload: OnStripeReady) => void;
  }>
) {
  const { checkout, children, onReady } = props;
  if (checkout == null) {
    return children;
  }
  return (
    <PaymentProvider checkout={checkout}>
      <StripeMethods onReady={onReady}>{children}</StripeMethods>
    </PaymentProvider>
  );
}

export function CheckoutContext({ children }: PropsWithChildren<{}>) {
  const [params] = useQueryParams(queries.payment);
  const [cart, setCart] = useState<Cart>();
  const initializeCheckoutMutation = useInitializeCheckoutMutation();

  const updateCartMutation = useUpdateCartMutation();
  const updateCartMutationMutate = updateCartMutation.mutateAsync;

  const validateTokenMutation = useValidateTokenMutation();
  const validateTokenMutationMutate = validateTokenMutation.mutateAsync;

  const [{ elements, stripe }, setStripe] = useState<OnStripeReady>({
    elements: null,
    stripe: null,
  });

  const hasFired = useRef(false);

  useEffect(() => {
    if (!updateCartMutation.data) {
      return;
    }
    setCart(updateCartMutation.data);
  }, [updateCartMutation.data]);

  const checkout = initializeCheckoutMutation.data;
  const discountCode = cart?.discountCodes.at(0) ?? params.code.at(0);

  const applyDiscountCode = useCallback(
    async (payload: { code: string }) => {
      try {
        const token = await validateTokenMutationMutate(payload.code);
        await updateCartMutationMutate({
          entries: [{ count: 1, productId: PRODUCT_ID }],
          discountCodes: [token.code!],
        });
      } catch (e) {
        // errors are handled in each mutation, this is just a wrapper on top
      }
    },
    [validateTokenMutationMutate, updateCartMutationMutate]
  );

  useEffect(() => {
    if (hasFired.current) {
      return;
    }
    hasFired.current = true;
    setTimeout(async () => {
      const discountCodes: string[] = [];
      try {
        const code = params.code.at(0);
        if (code) {
          const discountToken = await validateTokenMutation.mutateAsync(code);
          discountCodes.push(discountToken.code!);
        }
      } catch (e) {}
      await updateCartMutation.mutateAsync({
        entries: [{ count: 1, productId: PRODUCT_ID }],
        discountCodes,
      });
      await initializeCheckoutMutation.mutateAsync();
    }, 0);
  }, [
    updateCartMutation,
    initializeCheckoutMutation,
    params.code,
    validateTokenMutation,
  ]);

  const fullPrice = useMemo(
    () =>
      cart?.entries.reduce((acc, entry) => {
        const price: number = entry.price ?? 0;
        const count = entry.count ?? 1;
        return acc + price * count;
      }, 0),
    [cart]
  );

  const discountApplied = fullPrice !== cart?.cartTotal;

  const onStripeReady = useCallback((stripeApi: OnStripeReady) => {
    setStripe(stripeApi);
  }, []);

  return (
    <Context.Provider
      value={{
        stripe,
        elements,
        updateCartMutation,
        cart,
        checkout,
        validateTokenMutation,
        applyDiscountCode,
        fullPrice,
        discountCode,
        discountApplied,
      }}
    >
      <StipeProvider checkout={checkout} onReady={onStripeReady}>
        {children}
      </StipeProvider>
    </Context.Provider>
  );
}

export function useCheckoutContext() {
  const result = useContext(Context);
  if (result == null) {
    throw new Error("Cant use useCheckoutContext without Context");
  }
  return result;
}
