import PriceNumber from "render/ui/format/PriceNumber";
import { Typography } from "render/ui/presentation/Typography";
import { Cart } from "render/views/checkout/_CheckoutContext/hooks/useUpdateCartMutation/useUpdateCartMutation";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function ProductPrice({
  discountApplied,
  cart,
  discountCode,
  fullPrice,
}: {
  discountApplied: boolean;
  cart?: Cart;
  fullPrice?: number;
  discountCode?: string;
}) {
  return (
    <div className={styles.ProductPrice}>
      <>
        <Typography variant="body-label-m">
          <Trans.Product.Name />
        </Typography>
        <span className={styles.price}>
          <Typography variant="body-label-m" color="subtle">
            <Trans.Product.Total />
          </Typography>
          <Typography variant="body-label-m" color="default">
            {cart?.cartTotal != null && cart?.currency != null && (
              <PriceNumber price={cart?.cartTotal} currency={cart.currency} />
            )}
            {!cart && <span className={styles.pricePlaceholder} />}
          </Typography>
        </span>
      </>
      {discountApplied && (
        <>
          <Typography variant="body-s" color="subtle">
            <Trans.Code.Code /> {discountCode?.trim().toLocaleLowerCase()}
          </Typography>
          <div className={styles.lineThrough}>
            <Typography variant="body-s">
              {fullPrice != null && cart?.currency != null && (
                <PriceNumber price={fullPrice} currency={cart?.currency} />
              )}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
}
